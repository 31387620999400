import { createRouter, createWebHistory } from "vue-router";
import { init as fullStoryInit, shutdown as fullStoryShutdown } from "@/plugins/fullStory";
import constants from "@/utils/constants";
import routes from "@/router/routes";
import store from "@/store";
import { useGtm } from "@gtm-support/vue-gtm";

const isCheckoutRoute = (path) => /^\/checkout/.test(path);
const isPasswordRoute = (path) => /^\/(reset-password|reset-password-form)/.test(path);

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const id = to.hash.slice(1);
      const element = document.getElementById(id);
      setTimeout(() => {
        if (element) {
          document.getElementById(id).scrollIntoView();
        }
      }, 100);
    }
  },
});

router.beforeEach((to, from, next) => {
  const gtm = useGtm();

  if (!isPasswordRoute(to.fullPath)) {
    gtm.enable(process.env.NODE_ENV === "production" && process.env.VUE_APP_GTM_ID);
  }

  if (isCheckoutRoute(to.fullPath) && !isPasswordRoute(to.fullPath)) {
    fullStoryInit();
  } else {
    fullStoryShutdown();
  }

  if (
    to.name !== constants.ROUTES.MAINTENANCE &&
    constants.MODES.MAINTENANCE === store.getters["maintenance/getMode"]
  ) {
    next({ name: constants.ROUTES.MAINTENANCE });
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["auth/isLoggedIn"]) {
      // if already on login, routing to login can fail
      // handle that case by doing nothing
      if (from.name === "login") {
        return;
      }

      const query = { redirect: to.path };
      if (Object.keys(to.query).length > 0) {
        query.query = JSON.stringify(to.query);
      }
      next({
        name: "login",
        query,
      });
      return;
    }
    next();
    return;
  } else if (to.matched.some((record) => record.meta.authGuard)) {
    if (store.getters["auth/isLoggedIn"]) {
      next({
        name: constants.ROUTES.HOME,
      });
      return;
    }

    next();
    return;
  }

  next();
});

router.afterEach((to, from) => {
  store.dispatch("nav/setCurrentRoute", to);
  if (to.name === from.name) {
    return;
  }

  // document.title = docTitle(to);

  store.dispatch("analytics/send", { name: "navigation", data: to });
});

export default router;
