import "bootstrap";
import "@/styles/styles.scss";
import "@fontsource/work-sans/400.css";
import "@fontsource/work-sans/600.css";
import "@/utils/fontAwesomeIcons";
import App from "@/App.vue";
import { createApp } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import fullStoryPlugin from "@/plugins/fullStory";
import gtm from "@/utils/gtm";
import { request } from "leatherman-js";
import router from "@/router";
import store from "@/store";
import VueClipboard from "vue3-clipboard";

const app = createApp(App);

if (process.env.VUE_APP_API_URL) {
  request.setBaseUrl(process.env.VUE_APP_API_URL);
}

app.use(fullStoryPlugin, { activationRatio: 0.5 });

app.use(VueClipboard, { autoSetContainer: true, appendToBody: true });

app.directive("click-outside", {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

app.use(router);
app.use(store);
app.use(gtm);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");

const hideLoader = () => (document.getElementById("global-loader").style.display = "none");

window.onload = () => hideLoader();

if (document.readyState === "complete") {
  hideLoader();
}
